import { Routes } from '@angular/router';

export const routes: Routes = [
  {
    path: '',
    redirectTo: '',
    pathMatch: 'full',
  },
  {
    path: 'q/:code',
    loadComponent: () => import('./questions/questions.page').then( m => m.QuestionsPage)
  },
/*  {
    path: 'qr-generator',
    loadComponent: () => import('./qr-generator/qr-generator.page').then( m => m.QrGeneratorPage)
  },*/
  {
    path: 'q/:code/calc',
    loadComponent: () => import('./rechner/rechner.page').then( m => m.RechnerPage)
  },
  {
    path: 'q/:code/food-selection/:kat',
    loadComponent: () => import('./food-selection/food-selection.page').then( m => m.FoodSelectionPage)
  },
  {
    path: 'q/:code/finished',
    loadComponent: () => import('./finished/finished.page').then( m => m.FinishedPage)
  },
  {
    path: 'q/:code/thanks',
    loadComponent: () => import('./thanks/thanks.page').then( m => m.ThanksPage)
  },
  {
    path: 'q/:code/result',
    loadComponent: () => import('./results/results.page').then( m => m.ResultsPage)
  },
];
